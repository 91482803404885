import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";
import { RegistrationData } from "../models/registration_data";
import { ApplicationInfo } from "../models/application_info";
import { UserData } from "../models/user_data";
import { ProfileData } from "../models/profile_data";
import { MapToSierraAddress } from "../plugins/address-helper";
import { ParentApproval } from "../models/parent-approval";
import { RedirectResponse } from "../models/redirect_response";
import { UnregisterRequest } from "../models/uregister-request";
import { LogoutResponse } from "../models/logout-response";

export type StartRegistrationResponse = {
  fileCacheKey: string;
  requestCacheKey: string;
}

const registerPost = (data: RegistrationData | ProfileData, application: ApplicationInfo, locale: string, uri: string, download?: boolean, containerId?: string): Promise<any> => {
  let reqData = { ...data };
  reqData.field_values = { ...reqData.field_values };

  for (let fld of application.serviceRegFields) {
    if (fld.type === "address") {
      if (reqData.field_values && reqData.field_values[fld.name]) {
        reqData.field_values[fld.name] = MapToSierraAddress(reqData.field_values[fld.name]);
      }
    }
    else if (fld.type === "multi_choice") {
      if (reqData.field_values && reqData.field_values[fld.name]) {
        reqData.field_values[fld.name] = reqData.field_values[fld.name].length == 0 
          ? null 
          : reqData.field_values[fld.name].map((x: {label: string, value: string})=> x.value).join("$$");
      }
    }
  }

  return new Promise((resolve, reject) => {
    let url = BackendConfig.Url() + uri + "?language=" + locale;
    if (!!containerId)
      url += "&containerId=" + containerId;
    const fx = download ? BackendApi.postAndDownload : BackendApi.post;
    fx.call(BackendApi, url, reqData)
      .then(resp => resolve(resp))
      .catch(ex => reject(ex));
  });
}

export const RegisterService = {
  fetchUserData: async function (appId: string, returnUrl: string | null, oidc_access_token:string|null, local_data_only: boolean|null = null) {
    let queryParams = new URLSearchParams();
    queryParams.set("app_id", appId);
    if (returnUrl != null)
      queryParams.set("return_url", returnUrl);
    if (oidc_access_token != null)//used on mobile
      queryParams.set("oidc_access_token", oidc_access_token);
    if (local_data_only != null)
      queryParams.set("local_data_only", local_data_only.toString());

    const url = BackendConfig.Url() + "/Register/UserData?" + queryParams.toString();
    const result: UserData = await BackendApi.get(url);
    return result;
  },
  startRegister(data: RegistrationData, application: ApplicationInfo, locale: string): Promise<StartRegistrationResponse> {
    return registerPost(data, application, locale, "/Register/StartRegistration", false);
  },
  finishRegister(requestCacheKey: string, locale: string, containerId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = BackendConfig.Url() + `/Register/FinishRegistration?language=${locale}&requestCacheKey=${requestCacheKey}&containerId=${containerId}`;
      BackendApi.post(url)
        .then(resp => {
          resolve(resp);
        })
        .catch(ex => reject(ex));
    });
  },
  finishRegisterNoSign(requestCacheKey: string, locale: string, fileCacheKey: string): Promise<RedirectResponse> {
    return new Promise((resolve, reject) => {
      let url = BackendConfig.Url() + `/Register/FinishRegistrationNoSign?language=${locale}&requestCacheKey=${requestCacheKey}&fileCacheKey=${fileCacheKey}`;
      BackendApi.post(url)
        .then(resp => {
          if (resp.error || resp.errors) {
            reject(resp);
          }
          resolve(resp);
        })
        .catch(ex => reject(ex));
    });
  },
  //register(data: RegistrationData, application: ApplicationInfo, locale: string) {
  //    return registerPost(data, application, locale, "/Register/", false);
  //},
  generatePdf(data: RegistrationData, application: ApplicationInfo, locale: string) {
    return registerPost(data, application, locale, "/Register/GeneratePdf/", true);
  },
  save(data: ProfileData, application: ApplicationInfo, locale: string) {
    return registerPost(data, application, locale, "/Register/UserData/", false);
  },
  unregister(application: ApplicationInfo, locale: string) {
    return new Promise((resolve, reject) => {
      const url = BackendConfig.Url() + `/Register/Unregister/?language=${locale}`;
      let reqData = { app_id: application.appId };
      BackendApi.post(url, reqData)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
  startUnRegister(data: UnregisterRequest, application: ApplicationInfo, locale: string) {
    return new Promise((resolve, reject) => {
      const url = BackendConfig.Url() + `/UnRegister/StartUnRegistration/?language=${locale}`;
      BackendApi.post(url, data)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
  finishUnRegister(requestCacheKey: string, locale: string, containerId: string): Promise<LogoutResponse> {
    return new Promise((resolve, reject) => {
      let url = BackendConfig.Url() + `/UnRegister/FinishUnRegistration?language=${locale}&requestCacheKey=${requestCacheKey}&containerId=${containerId}`;
      BackendApi.post(url)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
  finishUnRegisterNoSign(requestCacheKey: string, locale: string, fileCacheKey: string): Promise<LogoutResponse> {
    return new Promise((resolve, reject) => {
      let url = BackendConfig.Url() + `/UnRegister/FinishUnRegistrationNoSign?language=${locale}&requestCacheKey=${requestCacheKey}&fileCacheKey=${fileCacheKey}`;
      BackendApi.post(url)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
  fetchParentApproval(application: ApplicationInfo, hash: string, locale: string) {
    return new Promise((resolve, reject) => {
      const url = BackendConfig.Url() + `/Register/ParentApproval?app_id=${application.appId}&language=${locale}&access_hash=${hash}`;
      BackendApi.cleanGet(url)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
  saveParentApproval(data: ParentApproval, application: ApplicationInfo, hash: string, locale: string) {
    return new Promise((resolve, reject) => {
      const url = BackendConfig.Url() + `/Register/ParentApproval?app_id=${application.appId}&language=${locale}&access_hash=${hash}`;
      BackendApi.cleanPost(url, data)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
  startParentApproval(data: ParentApproval, application: ApplicationInfo, hash: string, locale: string) {
    return new Promise((resolve, reject) => {
      const url = BackendConfig.Url() + `/Register/StartParentApproval?app_id=${application.appId}&language=${locale}&access_hash=${hash}`;
      BackendApi.cleanPost(url, data)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
  finishParentApproval(requestCacheKey: string, locale: string, containerId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = BackendConfig.Url() + `/Register/FinishParentApproval?language=${locale}&requestCacheKey=${requestCacheKey}&containerId=${containerId}`;
      BackendApi.post(url)
        .then(resp => resolve(resp))
        .catch(ex => reject(ex));
    });
  },
};
