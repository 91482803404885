import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import BackendConfig from "../../backend-config";
import { SessionInfo } from "../../models/session_info";
import { $t, t_key } from "../../plugins/i18n";

type ConfirmTermsFormPartProps = {
  session: SessionInfo,
  hasPrivacyPolicy: boolean,
  hasServiceTerms: boolean
}

const ConfirmTermsFormPart = (props: ConfirmTermsFormPartProps) => {
  const {control, formState: {errors}} = useFormContext();

  return (
    <Row className="mt-4">
      {props.hasPrivacyPolicy && <Col xs={12}>
        <Controller
          control={control}
          name={"confirm_privacy_terms"}
          render={({field}) => (
            <>
              <Form.Check
                id="privacy_terms_confirmation"
                ref={field.ref}
                checked={field.value}
                type="checkbox"
                inline
                onChange={field.onChange}
                aria-checked={field.value}
                label={
                  <span style={{marginLeft: "10px"}}>
                      <span>{$t(t_key.register_view.confirm_that_i_have_read)}&nbsp;</span>
                      <a href={BackendConfig.Url() + `/File/Services/${props.session.application?.appId}/PrivacyPolicy`} target="_blank">
                        {$t(t_key.register_view.personal_data_processing_procedure)}
                      </a>
                  </span>
                }
                isInvalid={!!errors.confirm_privacy_terms}
                aria-required={true}
                aria-describedby={
                  errors.field_values === undefined 
                    ? "" 
                    // @ts-ignore
                    : errors.field_values[field.name]?.message
                }
                aria-invalid={
                  errors.field_values !== undefined 
                    // @ts-ignore
                    && !!errors.field_values[field.name]
                }
              />

            </>
          )}
        />

        { errors.confirm_privacy_terms && 
          <Form.Text className="text-danger" role="alert">
            { errors.confirm_privacy_terms?.message as string }
          </Form.Text>
        }
      </Col>}

      {props.hasServiceTerms && <Col xs={12}>
        <Controller
          control={control}
          name={"confirm_service_terms"}
          render={({field}) => (
            <>
              <Form.Check
                id="service_terms_confirmation"
                ref={field.ref}
                checked={field.value}
                type="checkbox"
                inline
                onChange={field.onChange}
                aria-checked={field.value}
                label={
                  <span style={{marginLeft: "10px"}}>
                      <span>{$t(t_key.register_view.i_accept_service)}&nbsp;</span>
                      <a href={BackendConfig.Url() + `/File/Services/${props.session.application?.appId}/ServiceTerms`} target="_blank">
                        {$t(t_key.register_view.service_terms)}
                      </a>
                  </span>
                }
                isInvalid={!!errors.confirm_service_terms}
                aria-required={true}
                aria-describedby={
                  errors.field_values === undefined 
                    ? "" 
                    // @ts-ignore
                    : errors.field_values[field.name]?.message
                }
                aria-invalid={
                  errors.field_values !== undefined 
                    // @ts-ignore
                    && !!errors.field_values[field.name]
                }
              />
            </>
          )}
        />

        { errors.confirm_service_terms && 
          <Form.Text className="text-danger" role="alert">
            { errors.confirm_service_terms?.message as string }
          </Form.Text>
        }
      </Col>}
    </Row>
  )

}
export default ConfirmTermsFormPart;
