
export type ServiceAuth = {
    authType: string
}

export type ServiceRegField = {
    name: string;
    type: string;
    required: boolean;
    choices: string[] | null;
}

export enum ServiceRegFieldName {
  Birthday = "birthday",
  LocalGov = "local_gov",
  MobilePhone = "mobile_phone",
  Email = "email",
  Address = "address",
  FirstName = "first_name",
  LastName = "last_name",
  PersonCode = "person_code",
  Area = "area",
  Profession = "profession"
}

export type ApplicationInfo = {
    name: string;
    appId: string;
    type: string;
    design: string;
    serviceAuths: ServiceAuth[];
    regEnabled: boolean;
    cancelEnabled: boolean;
    regSignature: boolean;
    regSpecialRights: boolean;
    cancelSignature: boolean;
    systemNotification: string | null;
    systemNotificationEnabled: boolean;
    infoNotification: string | null;
    infoNotificationEnabled: boolean;
    hasPrivacyPolicy: boolean;
    hasServiceTerms: boolean;
    serviceRegFields: ServiceRegField[];
    returnUrl: string | null
}
