import { openPdf, downloadFile } from "./plugins/query-helper";
import  Constants from 'Constants';
import { getCookie } from "./plugins/cookie-helper";
const BackendApi = {
  get: async function (url: string, noloader?: boolean): Promise<any> {
    if (!noloader)
      this.showLoader();

    const data = await fetch(url, {
      method: "GET",
      redirect: "manual",
      credentials: "include",
      //credentials: "same-origin",
      headers: new Headers({
        "Content-Type": "application/json",
        "USER_SESSION_ID": this.getSessionId(),
      })
    });

    if (!data.ok) {
      this.handleError(data);
    }

    let resp: any = null;

    try {
      resp = await data.clone().json();
    } catch (err) {
      resp = await data.clone().text();
    }
    this.hideLoader();
    return resp;
  },

  getAndDownload: async function (url: string, filename: string, noloader?: boolean): Promise<any> {
    if (!noloader)
      this.showLoader();

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        redirect: "manual",
        credentials: "same-origin",
        headers: new Headers({
          "Content-Type": "application/json",
          "USER_SESSION_ID": this.getSessionId(),
        })
      }).then(resp => {
        if (resp.status == 200) {
          resp.headers.forEach(console.log);
          resp.blob().then(b => downloadFile(b, filename));
          resolve({ success: true });
        }
        else {
          resp.clone().json().then(j => resolve(j));
        }
        this.hideLoader();
      })
        .catch(ex => {
          this.hideLoader();
          reject(ex);
        });
    });
  },

  post: async function (url: string, requestbody?: any, noloader?: boolean): Promise<any> {
    if (url.indexOf("/Logging/") == -1 && !noloader)//do not show loader for logging requests
      this.showLoader();

    const data = await fetch(url, {
      method: "POST",
      redirect: "manual",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
        "USER_SESSION_ID": this.getSessionId(),
      }),
      body: JSON.stringify(requestbody)
    });

    if (!data.ok) {
      this.handleError(data);
    }

    let resp: any = null;

    try {
      resp = await data.clone().json();
    } catch (err) {
      resp = await data.clone().text();
    }

    this.hideLoader();
    return resp;
  },

  postAndDownload: function (url: string, requestbody?: any): Promise<any> {
    this.showLoader();

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        redirect: "manual",
        credentials: "include",
        headers: new Headers({
          "Content-Type": "application/json",
          "USER_SESSION_ID": this.getSessionId(),
        }),
        body: JSON.stringify(requestbody)
      })
        .then(resp => {
          if (resp.status == 200) {
            resp.blob().then(b => openPdf(b));
            resolve({ success: true });
          }
          else {
            resp.clone().json().then(j => resolve(j));
          }
          this.hideLoader();
        })
        .catch(ex => {
          this.hideLoader();
          console.log(JSON.stringify(ex));
          reject(ex);
        });
    });
  },

    cleanPost: function (url: string, requestbody?: any): Promise<any> {
        this.showLoader();

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                redirect: "manual",
                credentials: "include",
                headers: new Headers({
                  "Content-Type": "application/json",
                  "USER_SESSION_ID": this.getSessionId(),
                }),
                body: JSON.stringify(requestbody)
            })
                .then(resp => {
                    // TODO why is 400 handled here???? 400 should not be considered a success
                    if (resp.status == 200 || resp.status == 400) {
                        resp.clone().json().then(j => resolve(j));
                    }
                    else {
                        console.error(resp);
                        resp.clone().json().then(j => reject(j)).catch(() => reject(null));
                    }
                })
                .catch(ex => {
                    console.error(ex);
                    reject(ex);
                })
                .finally(() => {
                    this.hideLoader();
                });
        });
    },

    cleanGet: function (url: string): Promise<any> {
        this.showLoader();

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                redirect: "manual",
                credentials: "include",
                headers: new Headers({
                  "Content-Type": "application/json",
                  "USER_SESSION_ID": this.getSessionId(),
                })
            })
                .then(resp => {
                    if (resp.status == 200 || resp.status == 400) {
                        resp.clone().json().then(j => resolve(j));
                    }
                    else {
                        reject(resp);
                    }
                })
                .catch(ex => {
                    console.error(ex);
                    reject(ex);
                })
                .finally(() => {
                    this.hideLoader();
                });
        });
    },

  handleError(data: any) {
    this.hideLoader();

    if (data.type === "opaqueredirect") {
      //redirect
      if (data.url)
        location.href = data.url;
    }


    if (data.status === 400) {
      //Not found

    }
    else if (data.status === 401) {
      //Unauthorized
    }
    else if (data.status === 403) {
      //Forbidden for user
    }
    else if (data.status === 500) {
      //Server error
    }
  },

  showLoader() {
    const loader = document.getElementById("loader");
    if (loader)
      loader.style.display = "grid";
  },
  hideLoader() {
    const loader = document.getElementById("loader");
    if (loader)
      loader.style.display = "none";
  },

  getSessionId(): string {
    let sessString: string | null = getCookie(Constants.USER_SESSION_ID);
    if (sessString == null) {
      sessString = this.uuidv4();
      document.cookie = `${Constants.USER_SESSION_ID}=${sessString}; SameSite=lax; Secure; Path=/`;
    }
    return sessString;
  },
  uuidv4(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
};
export default BackendApi;