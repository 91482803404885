import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import ConfirmTermsFormPart from "../components/register/confirm-terms-form-part";
import ParentFormPart from "../components/register/parent-form-part";
import ServiceInfoRow from "../components/register/service-info-row";
import { ParentApproval } from "../models/parent-approval";
import { SessionInfo } from "../models/session_info";
import { $t, t_key } from "../plugins/i18n";
import { RegisterService } from "../services/register-service";
import { useParams, useNavigate } from "react-router-dom";
import { showError } from "../plugins/validation";
import ParentApprovalNoAccessModal from "../components/register/parent-approval-no-access-modal";
import { ModalProviderContext } from "../components/modals/modal-provider";
import SigningMethodModal from "../components/modals/signing/signing-method-modal";
import { useTranslation } from 'react-i18next';
import ErrorModal from "../components/error-modal";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { SpecialRightsFormPart } from "components/special-rights/special-rights-form-part";
import { fileToBase64 } from "utils/file-utils";
import Constants from "Constants";

export type ParentApprovalViewProps = {
  session: SessionInfo
}

const ParentApprovalView = (props: ParentApprovalViewProps) => {
  const [formData, setFormData] = React.useState<ParentApproval | null>(null);
  const [noAccessModal, setNoAccessModal] = React.useState<boolean>(false);
  const [specialRightsFiles, setSpecialRightsFiles] = useState<File[] | null>(null);
  const [errorModal, setErrorModal] = React.useState<{ [key: string]: any }>({ open: false, message: "" });
  const { showModal } = useContext(ModalProviderContext);

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { access_hash } = useParams();

  const validationSchema = yup.object().shape({
    app_id: yup.string(),
    parent_last_name: yup.string(),
    parent_first_name: yup.string(),
    parent_email: yup.string().email($t(t_key.validation.invalid_email)).required($t(t_key.validation.required_field)),
    parent_person_code: yup.string(),
    requires_parent_approval: yup.boolean(),
    requires_signing_privacy_terms: yup.boolean(),
    requires_signing_service_terms: yup.boolean(),
    confirm_privacy_terms: yup.boolean().when("requires_signing_privacy_terms", {
      is: true,
      then: (s) => s.oneOf([true], $t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    confirm_service_terms: yup.boolean().when("requires_signing_service_terms", {
      is: true,
      then: (s) => s.oneOf([true], $t(t_key.validation.required_field)),
      otherwise: (s) => s.notRequired()
    }),
    access_hash: yup.string(),
    special_rights_requested: yup.boolean(),
    special_rights_files: yup.mixed()
  });

  const methods = useForm({
    mode:"all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      access_hash: "",
      app_id: "",
      parent_last_name: "",
      parent_first_name: "",
      parent_email: "",
      parent_person_code: "",
      confirm_privacy_terms: false,
      confirm_service_terms: false,
      requires_parent_approval: false,
      special_rights_files: [],
      special_rights_requested: false
    },
  });

  const login = () => {
    navigate(`/?app_id=${props.session.application?.appId}`);
  }

  useEffect(() => {
    const loadData = () => {
      if (props.session.application == null || !access_hash) {
        return;
      }

      RegisterService.fetchParentApproval(
        props.session.application,
        access_hash,
        i18n.language
      ).then(resp => {
          let data = resp as ParentApproval;
          data.confirm_privacy_terms = false;
          data.confirm_service_terms = false;
          data.app_id = props.session.application?.appId ?? "";
          data.access_hash = access_hash;
          setFormData(data);
        })
        .catch(ex => {
          if (ex.status === 401) {
            login();
            return;
          }
          if (ex.status === 403) {
            setNoAccessModal(true);
            return;
          }
          showError(ex);
        });
    };
    loadData();
  }, []);

  useEffect(() => {
    if (!formData) return;

    methods.setValue("app_id", formData.app_id)
    methods.setValue("access_hash", formData.access_hash)

    methods.setValue("parent_person_code", formData.parent_person_code)
    methods.setValue("parent_last_name", formData.parent_last_name)
    methods.setValue("parent_first_name", formData.parent_first_name)

    methods.setValue("special_rights_requested", formData.special_rights_requested)
  }, [formData])

  useEffect(() => {
    const setFiles = async () => {
      if (specialRightsFiles) {
        let files = [];
        for (var i = 0; i < (specialRightsFiles?.length ?? 0); i++) {
          files.push({
            name: specialRightsFiles![i].name,
            data: await fileToBase64(specialRightsFiles![i]),
            size: specialRightsFiles![i].size
          });
        }
        methods.setValue("special_rights_files", files)
      }
    }
    setFiles();

  }, [specialRightsFiles])

  const cancelClick = () => {
    closeForm();
  }

  const finishApproval = (data: any, containerId: string) => {
    RegisterService.finishParentApproval(data.reqKey, i18n.language, containerId);
  }

  const closeForm = () => {
    window.location.href = window.location.origin;
  }

  const onSubmit = (data: any) => {
    if (!data || props.session.application == null || !access_hash) {
      return;
    }

    if (props.session.application?.regSignature) {
      RegisterService.startParentApproval(
        data,
        props.session.application,
        access_hash,
        i18n.language
      ).then((resp: any) => {
          if (resp.errors && resp.errors.global) {
            setErrorModal({ open: true, message: $t(resp.errors.global[0]) });
            return;
          }

          showModal(<SigningMethodModal
            cacheKey={resp.fileCacheKey}
            data={{ reqKey: resp.requestCacheKey, application: props.session.application }}
            showConsentForm={true}
            onFinish={finishApproval}
            identificationCode={methods.getValues("parent_person_code")}
            onClose={closeForm}
            phoneNr={""} />
          );
        })
        .catch(ex => showError(ex));
    }
    else {
      RegisterService.saveParentApproval(
        data,
        props.session.application, 
        access_hash,
        i18n.language
      ).then((resp: any) => {
          if (resp.errors === undefined && resp.error === undefined) {
            closeForm();
          }
        }).catch(ex => showError(ex));
    }
  }

  const onSubmitError = (data: any) => {
    console.error(data);
  }

  const renderForm = () => {
    if (props.session.application == null) return null;

    if (formData != null) {
      return (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit, onSubmitError)} className="data-form">
            <h1 tabIndex={0} id={Constants.MAIN_CONTENT}>{$t(t_key.parent_approval_view.title)}</h1>
            <ServiceInfoRow session={props.session} />

            <h2 tabIndex={-1}>
              {$t(t_key.parent_approval_view.child)}:
              <span className="lighter">{formData.first_name} {formData.last_name}</span>
            </h2>

            <ParentFormPart 
              registerUnderAge={true} 
              requiresParentApproval={methods.getValues("requires_parent_approval") === true}
            />

            { formData.special_rights_requested &&
              <SpecialRightsFormPart setFiles={setSpecialRightsFiles} />
            }

            <ConfirmTermsFormPart 
              session={props.session}
              hasPrivacyPolicy={props.session.application?.hasPrivacyPolicy ?? true}
              hasServiceTerms={props.session.application?.hasServiceTerms ?? true}
            />

            <Row>
              <Col xs={12} className="form-buttons mt-3">
                <Button variant="secondary" onClick={cancelClick}>
                  {$t(t_key.buttons.cancel)}
                </Button>

                <Button variant="primary" type="submit">
                  {$t(t_key.buttons.confirm_and_sign)}
                </Button>
              </Col>
            </Row>

            {errorModal.open ? <ErrorModal
              show={errorModal.open}
              message={errorModal.message}
              onClose={() => setErrorModal({ open: false, message: "" })}
            /> : null}

          </Form>
        </FormProvider>
      )
    } else if (noAccessModal) {
      return (
        <ParentApprovalNoAccessModal 
          show={noAccessModal} 
          app={props.session.application} 
          onClose={() => setNoAccessModal(false)} onLogin={login} 
        />
      )
    } else {
      return null;
    }
  }

  return <Container>{renderForm()}</Container>
}

ParentApprovalView.displayName = "ParentApprovalView";

export default ParentApprovalView;
