import { Session } from "inspector";
import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import RegisterModal from "../components/register/register-modal";
import { SessionInfo, UserStatus } from "../models/session_info";
import { RegisterService } from "../services/register-service";
import { $t, t_key } from "../plugins/i18n";
import Constants from "../Constants";
import Loader from "../components/loader";
import { ModalProviderContext } from "components/modals/modal-provider";
import ErrorModal from "components/modals/error-modal";

export type LoginSuccessProps = {
  session: SessionInfo,
  message: string
}

const LoginSuccess = (props: LoginSuccessProps) => {
  const [registerModal, setRegisterModal] = React.useState<boolean>(false);
  const [redirectTo, setRedirectTo] = React.useState<string>('');
  const [msg, setMsg] = React.useState<string>(props.message);

  const { showModal } = useContext(ModalProviderContext);

  const doRedirect = (to:string) => {
    if (to) {
      window.open(to, "_self");
    }
  }

  //TODO 76 cookie etc
  React.useEffect(() => {

    if (props.session.error) {
      setMsg($t(t_key.login_failed_text.message));
      const referrer = sessionStorage.getItem(Constants.URL_REFERRER);
      if (referrer)
        window.setTimeout(() => { window.location.href = referrer; }, 1500); 
      return;
    }

    if (props.session.session?.user_status === UserStatus.NoAccount || props.session.session?.user_status === UserStatus.NoAppRegistered) {
      setRegisterModal(true);
    }
    else if (props.session.session?.user_status === UserStatus.UnauthorizedToRegister) {
      showModal(<ErrorModal title={$t("validation.error_modal_title")} content={$t("validation.not_allowed_to_register")} onClose={cancelRegistration} />);
    }
    else {
      setRedirectTo(props.session.redirectUrl ?? '');
      doRedirect(props.session.redirectUrl ?? '');
    }
    
  }, []);


  const cancelRegistration = async () => {
    setRegisterModal(false);

    if (props.session?.application?.appId) {
      const userData = await RegisterService.fetchUserData(props.session.application.appId, null, null);
      if (userData?.register_timeout_redirecturl) {
        location.href = userData?.register_timeout_redirecturl;
      }
    }
  }

  return (
    <Container className="content-area">
      <Row>
        <Col xs={12} className="col1">
          {redirectTo  && <Loader />}
          {props.session.application == null ? null : <RegisterModal show={registerModal} session={props.session} onClose={() => cancelRegistration()} />}
          <h1 tabIndex={0}>{msg}</h1>         
        </Col>
      </Row>
    </Container>
  )
}

export default LoginSuccess;
