import React from "react";
import { Col, Row } from "react-bootstrap";
import { $t, t_key } from "../../plugins/i18n";

type DocumentsToSignFormPartProps = {
    readOnly: boolean,
    className?: string,
    showConsentForm?: boolean,
    unRegistration?: boolean,
    hasPrivacyPolicy?: boolean,
    hasServiceTerms?: boolean,
    generatePdf(e: any): void
}

const DocumentsToSignFormPart = (props: DocumentsToSignFormPartProps) => {
    return <div className={props.className}>
        <h2 tabIndex={-1} className="mt-3">{$t(t_key.register_view.documents_to_sign)}</h2>
        <Row className="mt-3">
            <Col xs={12}>
                {props.unRegistration ?
                    <ul className="form-inline-list">
                        <li>{props.readOnly ? $t(t_key.register_view.unregistration_application_pdf) : <a href="#" onClick={props.generatePdf}>{$t(t_key.register_view.unregistration_application_pdf)}</a>}</li>
                    </ul> :
                    <ul className="form-inline-list">
                        <li>{props.readOnly ? $t(t_key.register_view.registration_application_pdf) : <a href="#" onClick={props.generatePdf}>{$t(t_key.register_view.registration_application_pdf)}</a>}</li>
                        {props.hasServiceTerms && <li>{props.readOnly ? $t(t_key.register_view.service_terms_pdf) : <a href="#">{$t(t_key.register_view.service_terms_pdf)}</a>}</li>}
                        {props.hasPrivacyPolicy && <li>{props.readOnly ? $t(t_key.register_view.personal_data_processing_procedure_pdf) : <a href="#">{$t(t_key.register_view.personal_data_processing_procedure_pdf)}</a>}</li>}
                        {props.showConsentForm ? <li>{props.readOnly ? $t(t_key.register_view.consent_form_pdf) : <a href="#">{$t(t_key.register_view.consent_form_pdf)}</a>}</li> : null}
                    </ul>}
            </Col>
        </Row>
    </div>
}

export default DocumentsToSignFormPart;
